@import '../../../styles/variables.module';
@import '../../../styles/mixins.module';

.container {
  @include container;
}

.wrapper {
  position: relative;
  padding-bottom: $space;

  @include breakpoint($bp-md) {
    padding-bottom: 50px;
    min-height: calc(100vh - #{$footer-height});
  }
}
