@import '../../../styles/mixins.module';
@import '../../../styles/variables.module';
@import '../../../styles/utilities.module';

.uploader {
  h4 {
    margin: 0 0 5px;
  }
}

.uploader-contents {
  display: flex;
  flex-direction: column;
  align-items: center;

  @include breakpoint($bp-sm) {
    flex-direction: row;
  }
}

.img {
  margin-bottom: $space / 2;
  border: 1px solid $grey-light;
  max-width: 100%;

  @include breakpoint($bp-sm) {
    max-width: auto;
    margin: 0 $space 0 0;
  }
}

.uploader-input {
  display: flex;
  flex-direction: column;
}

.input {
  display: none;
}

.upload-button {
  margin: 0 10px 0 0;
}

.reqs {
  margin-top: 5px;
}

.icon {
  color: $success;
  margin-left: 5px;
}
