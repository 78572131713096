@import '../../../styles/variables.module';
@import '../../../styles/mixins.module';

.container {
  @include breakpoint($bp-md) {
    display: grid;
    grid-column-gap: $space;
    grid-row-gap: $space;
    grid-template-columns: 2fr 1fr;
  }

  @include breakpoint($max-width + 40) {
    grid-template-columns: 2fr minmax(250px, 1fr);
  }
}
