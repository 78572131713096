@import '../../../styles/variables.module';
@import '../../../styles/mixins.module';

.switch {
  display: flex;
  align-items: center;

  & > span {
    margin: 0 5px;

    button {
      float: left;
      cursor: pointer;
    }
  }
}

.icon--off {
  font-size: 2rem;
  width: 20px;
  height: 20px;
  line-height: 20px;

  @include breakpoint($bp-md) {
    font-size: 3rem;
    width: 30px;
    height: 30px;
    line-height: 30px;
  }
}

.icon--on {
  font-size: 2.4rem;
  width: 24px;
  height: 24px;
  line-height: 24px;

  @include breakpoint($bp-md) {
    font-size: 3.4rem;
    width: 34px;
    height: 34px;
    line-height: 34px;
  }
}
