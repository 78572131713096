@import '../../../styles/variables.module';
@import '../../../styles/mixins.module';
@import '../../../styles/utilities.module';

.change-email {
  margin-bottom: $space / 2;
  background: $white-dark;
  padding: $space / 2;

  @include breakpoint($bp-sm) {
    margin-bottom: $space;
    padding: $space;
  }

  h3 {
    margin-top: 0;
  }
}

.form {
  @extend %form;
  padding: 0;
}

.form-field-wrapper {
  @extend %form-field-wrapper;
}

.form-label {
  @extend %form-label;
}

.form-field {
  @extend %form-field;
}
