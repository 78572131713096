@import '../../../styles/utilities.module';

.form {
  @extend %form;
}

.form-field {
  @extend %form-field-wrapper;

  [type='radio'] {
    margin-right: 5px;
  }

  label + label {
    margin-left: 10px;
  }
}

.rig-form-label {
  @extend %form-label;
}

.rig-form-field {
  @extend %form-field;
}

.form-footer {
  @extend %form-footer;
}
