@import '../../../styles/utilities.module';
@import '../../../styles/mixins.module';
@import '../../../styles/variables.module';

.nav {
  margin-bottom: $space / 2;

  @include breakpoint($bp-sm) {
    margin-bottom: $space;
  }
}
