@import '../variables.module.scss';
@import '../mixins.module.scss';

%form {
  padding: $space;
  margin: $space 0;
  background: $white-dark;
}

%form-field-wrapper {
  margin-bottom: $space;

  @include breakpoint($bp-sm) {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-column-gap: ($space / 2);
  }

  @include breakpoint($bp-md) {
    grid-column-gap: $space;
    margin-bottom: $space;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

%form-label {
  text-align: right;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-self: end;
}

%form-select {
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 1.3;
  padding: 7px 10px;
  box-sizing: border-box;
  margin: 0;
  border: 1px solid $grey-light;
  box-shadow: none;
  border-radius: 0;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: $white;
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'),
    linear-gradient(to bottom, #{$white} 0%, #{$white} 100%);
  background-repeat: no-repeat, repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-size: 0.65em auto, 100%;

  &::-ms-expand {
    display: none;
  }
  &:focus {
    border-color: $grey;
    box-shadow: 0 0 1px 3px rgba(59, 153, 252, 0.7);
    box-shadow: 0 0 0 3px -moz-mac-focusring;
    color: #222;
    outline: none;
  }
  option {
    font-weight: normal;
  }

  /* Disabled styles */
  &:disabled,
  &[aria-disabled='true'] {
    color: graytext;
    background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22graytext%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'),
      linear-gradient(to bottom, #{$grey} 0%, #{$grey} 100%);
  }
  &:disabled:hover,
  &[aria-disabled='true'] {
    cursor: not-allowed;
  }
}

%label-radio,
%label-checkbox {
  margin: 0;

  & + & {
    margin-left: ($space / 2);

    @include breakpoint($bp-sm) {
      margin-left: $space;
    }
  }

  [type='radio'] {
    margin-right: 5px;
  }
}

%form-radio {
  display: flex;
  align-items: center;
}

%form-field {
  input[type='text'],
  input[type='email'],
  input[type='number'],
  input[type='password'] {
    border: 1px solid $grey-light;
    padding: 3px 5px;
    width: 100%;
    line-height: 1;
    font-size: 1.6rem;

    @include breakpoint($bp-sm) {
      width: 300px;
      padding: 7px 10px;
    }

    &:disabled {
      background: $grey;
      cursor: not-allowed;
    }
  }

  input[type='date'] {
    width: auto;
  }

  input[type='radio'] {
    margin-right: 5px;
  }

  select {
    @extend %form-select;
    width: 100%;

    @include breakpoint($bp-sm) {
      width: 300px;
    }
  }
}

%date-field {
  background: white;
  padding: 3px 5px;
  width: 100%;
  line-height: 1;
  font-size: 1.6rem;
  border: 1px solid $grey-light;

  @include breakpoint($bp-sm) {
    width: 300px;
    padding: 7px 10px;
  }

  & > div {
    border: none;
  }
}

%date-field--disabled {
  background: $grey !important;
  cursor: not-allowed !important;
}

%form-footer {
  text-align: center;
}

%form-captcha {
  margin: 0 auto $space;
  // display: flex;
  // justify-content: center;
}

%form-error-message {
  color: $failure;
  margin-top: 2px;
  font-size: 1.4rem;
}

%form-uploader {
  border: 2px solid black;
  border-radius: 0;
  background: transparent;
  box-shadow: none;
  padding: 8px 8px 5px;
  font-family: $font-primary;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 11px;
  letter-spacing: 1px;
  line-height: 1;
  color: black;
  transition: 0.3s;
  cursor: pointer;
}
