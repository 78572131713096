@import '../../../styles/utilities.module';
@import '../../../styles/mixins.module';
@import '../../../styles/variables.module';

.user-details {
  small {
    text-transform: uppercase;
    font-weight: 600;
    margin-left: 20px;
  }
}

dl {
  @include clearfix;
  padding: 0;
  margin: 0;
  display: grid;
  width: 100%;

  @include breakpoint($bp-sm) {
    grid-template-columns: 1fr 2fr;
    grid-column-gap: ($space / 1);
  }

  @include breakpoint($bp-xl) {
    grid-template-columns: 1fr 3fr;
    grid-column-gap: $space;
  }

  dt {
    font-weight: bold;
    padding: 0;
    margin: 0;
  }

  dd {
    padding: 0;
    margin: 0 0 $space;
  }

  address {
    font-style: normal;
  }
}
