@import '../../../styles/utilities.module';

.form {
  @extend %form;
}

.form-field-wrapper {
  @extend %form-field-wrapper;
}

.profile-form-label {
  @extend %form-label;
}

.profile-radio-field {
  display: flex;
}

.profile-radio-label {
  @extend %label-radio;
  line-height: 1;
}

.profile-form-field {
  @extend %form-field;
}

.profile-date-field {
  @extend %date-field;
}

.profile-date-field--disabled {
  @extend %date-field--disabled;
}

.form-footer {
  @extend %form-footer;
}
