@import '../../../styles/variables.module';
@import '../../../styles/utilities.module';

.new-accounts-table {
  @extend %table;

  tr {
    grid-gap: 2px;
    grid-template-columns: 44px 1fr 1fr;
    grid-template-rows: repeat(3, 1fr);

    @include breakpoint($bp-sm) {
      display: table-row;
    }
  }

  td {
    &:nth-of-type(1) {
      grid-column: 1 / 2;
      grid-row: 1 / 4;
      padding: 5px;
      align-self: start;
      justify-self: center;
    }

    &:nth-of-type(2) {
      // padding: 5px 7px 5px 0;
      grid-column: 2 / span 2;
      grid-row: 1 / 2;
      padding-top: 5px;
    }

    &:nth-of-type(3) {
      grid-column: 2 / span 2;
      grid-row: 2 / 3;
    }

    &:nth-of-type(4) {
      grid-column: 2 / span 2;
      grid-row: 3 / 4;
      padding-bottom: 5px;
    }
  }
}

.reason-input {
  @extend %date-field;
  margin-bottom: 20px;
}
