@import '../../../styles/utilities.module';
@import '../../../styles/mixins.module';
@import '../../../styles/variables.module';

.form {
  @extend %form;
}

.form-field-wrapper {
  @extend %form-field-wrapper;
}

.form-field {
  @extend %form-field;
}

.form-label {
  @extend %form-label;
}

.form-footer {
  @extend %form-footer;
}

.recaptcha {
  @extend %form-captcha;
}
