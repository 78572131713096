@import '../../../styles/utilities.module';

.form {
  @extend %form;
}

.form-field-wrapper {
  @extend %form-field-wrapper;
}

.form-label {
  @extend %form-label;
}

.form-field {
  @extend %form-field;
}

.recaptcha {
  @extend %form-captcha;
}
