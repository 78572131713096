.button-list {
  margin: 0 0 20px;
  padding: 0;
  list-style: none;

  li {
    margin: 0 10px 10px 0;
    padding: 0;
    display: inline-block;
  }
}
